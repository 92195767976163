type ENV = 'dev' | 'prod' | 'demo'

export const currentEnv: ENV = "prod";

const env: ENV = currentEnv;

export const Env = env

const API_DEV_URL = "https://mon-docteur-api.volkeno-engineering.click";
const API_DEMO_URL = "https://mon-docteur-api.volkeno-engineering.click";
const API_PROD_URL = "https://api.mon-docteur.org";

export const APP_URL_DEV = "https://mon-docteur-app.volkeno-engineering.click/";
export const APP_URL_DEMO = "https://mon-docteur-app.volkeno-engineering.click/";
export const APP_URL_PROD = "https://app.mon-docteur.org/";

export const SOCKET_DEV_URL = "164.92.136.142:4026"; //"http://localhost:4000";
export const SOCKET_DEMO_URL = "164.92.136.142:4026";
export const SOCKET_PROD_URL = "164.92.136.142:4026";


function processApiUrl() {
	if (env === "prod") return API_PROD_URL;
	if (env === "demo") return API_DEMO_URL;
	return API_DEV_URL;
}

function processAppUrl() {
  if (env === "prod") return APP_URL_PROD;
  if (env === "demo") return APP_URL_DEMO;
  return APP_URL_DEV;
}

function processSocketUrl() {
  if (env === "prod") return SOCKET_PROD_URL;
  if (env === "demo") return SOCKET_DEMO_URL;
  return SOCKET_DEV_URL;
}
export const ApiBaseUrl = processApiUrl();
export const AppBaseUrl = processAppUrl();
export const SocketUrl = processSocketUrl();


export const GoogleApikey = "AIzaSyD0klc1PQS8QK--Be3rF3i8kW_idy04AVQ";