import React, { ReactElement } from "react";
import { Navigate } from "react-router-dom";
import DashboardAdmin from "../components/admin/DashboardAdmin/DashboardAdmin";
import Login from "../components/auth/Login";
import ForgetPassword from "../components/auth/ForgetPassword";
import ForgetPasswordPhone from "../components/auth/ForgetPasswordPhone";
import ResetPassword from "../components/auth/ResetPassword";
import AdminLayout from "../components/shared/AdminLayout/AdminLayout";
import Register from "../components/auth/Register";
import PagesError404 from "../components/shared/PagesError/PagesError404";
import PatientsAdmin from "../components/admin/PatientsAdmin/PatientsAdmin";
import AddOrUpdatePatient from "../components/admin/PatientsAdmin/AddOrUpdatePatient";
import PatientDetail from "../components/admin/PatientsAdmin/PatientDetail";
import PraticiensAdmin from "../components/admin/PraticiensAdmin/PraticiensAdmin";
import AddOrUpdatePraticien from "../components/admin/PraticiensAdmin/AddOrUpdatePraticien";
import PraticienDetail from "../components/admin/PraticiensAdmin/PraticienDetail";
import RdvAdmin from "../components/admin/RdvAdmin/RdvAdmin";
import DetailRdv from "../components/admin/RdvAdmin/DetailRdv";
import PaiementAdmin from "../components/admin/PaiementAdmin/PaiementAdmin";
import PaiementDetail from "../components/admin/PaiementAdmin/PaiementDetail";
import RemboursementAdmin from "../components/admin/RemboursementAdmin/RemboursementAdmin";
import RemboursementDetail from "../components/admin/RemboursementAdmin/RemboursementDetail";
import ProfilAdmin from "../components/admin/Profil/ProfilAdmin";
import ParametreAdmin from "../components/admin/ParametreAdmin/ParametreAdmin";
import AddAdmin from "../components/admin/ParametreAdmin/AddAdmin";
import ShowAdmin from "../components/admin/ParametreAdmin/ShowAdmin";
import AddOrUpdateCondition from "../components/admin/ParametreAdmin/AddOrUpdateCondition";
import AddOrUpdatePolitique from "../components/admin/ParametreAdmin/AddOrUpdatePolitique";
import MessagesContacts from "../components/admin/MessagesContacts/MessagesContacts";
import { isAdmin, isSuperAdmin } from "../utils/Utils";
import { useAppSelector } from "../redux/hook";
import FormatsAdmin from "../components/admin/FormatsAdmin/FormatsAdmin";
import AddOrUpdateFormat from "../components/admin/FormatsAdmin/AddOrUpdateFormat";
import FormatDetail from "../components/admin/FormatsAdmin/FormatDetail";
import MessageContactDetail from "../components/admin/MessagesContacts/MessageContactDetail";
import AddOrUpdateApropos from "../components/admin/ParametreAdmin/AddOrUpdateApropos";
import AddOrUpdateRdv from "../components/admin/RdvAdmin/AddOrUpdateRdv";
import UsersAdmin from "../components/admin/UsersAdmin/UsersAdmin";
import UserDetail from "../components/admin/UsersAdmin/UserDetail";
import HistoriquesAdmin from "../components/admin/Historiques/HistoriquesAdmin";
import CguPage from "../components/publicPage/CguPage";
import PolicyPage from "../components/publicPage/PolicyPage";

// const socket = io(SocketUrl);

const IsAdminAllow = ({ children }: { children: ReactElement }) => {
  const { user, token } = useAppSelector((state) => state.user);

  let path: string = "/";
  if (token) {
    if (isSuperAdmin(user) || isAdmin(user)) {
      return children;
    }
  }

  return !token || (!isSuperAdmin(user) && !isAdmin(user)) ? (
    <Navigate to={path} replace />
  ) : (
    children
  );
};

// UserRoutes
export const AppRoutes = [
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/mot-de-passe-oublie",
    element: <ForgetPassword />,
  },
  {
    path: "/mot-de-passe-oublie-telephone",
    element: <ForgetPasswordPhone />,
  },
  {
    path: "/reinitialiser-mot-de-passe",
    element: <ResetPassword />,
  },
  {
    path: "/inscription",
    element: <Register />,
  },
  {
    path: "*",
    element: <PagesError404 />,
  },
  {
		path: "/conditions-generales-utilisation",
		element: <CguPage />,
	},
  {
		path: "/politiques-confidentialites",
		element: <PolicyPage />,
	},
  {
    path: "admin/",
    element: (
      <IsAdminAllow>
        <AdminLayout />
      </IsAdminAllow>
    ),
    children: [
      { element: <Navigate to="/admin/dashboard" replace />, index: true },
      {
        path: "dashboard",
        element: <DashboardAdmin />,
      },
      {
        path: "utilisateurs",
        element: <UsersAdmin />,
      },
      {
        path: "utilisateurs/detail-utilisateur/:slug",
        element: <UserDetail />,
      },
      {
        path: "patients",
        element: <PatientsAdmin />,
      },
      {
        path: "historiques",
        element: <HistoriquesAdmin />,
      },
      {
        path: "patients/ajouter-patient",
        element: <AddOrUpdatePatient />,
      },
      {
        path: "patients/modifier-patient/:slug",
        element: <AddOrUpdatePatient />,
      },
      {
        path: "patients/detail-patient/:slug",
        element: <PatientDetail />,
      },
      {
        path: "formats",
        element: <FormatsAdmin />,
      },
      {
        path: "formats/ajouter-format",
        element: <AddOrUpdateFormat />,
      },
      {
        path: "formats/modifier-format/:slug",
        element: <AddOrUpdateFormat />,
      },
      {
        path: "formats/detail-format/:slug",
        element: <FormatDetail />,
      },
      {
        path: "praticiens",
        element: <PraticiensAdmin />,
      },
      {
        path: "praticiens/ajouter-praticien",
        element: <AddOrUpdatePraticien />,
      },
      {
        path: "praticiens/modifier-praticien/:slug",
        element: <AddOrUpdatePraticien />,
      },
      {
        path: "praticiens/detail-praticien/:slug",
        element: <PraticienDetail />,
      },
      {
        path: "rendez-vous",
        element: <RdvAdmin />,
      },
      {
        path: "rendez-vous/:slug",
        element: <DetailRdv />,
      },
      {
        path: "rendez-vous/ajouter-rendez-vous",
        element: <AddOrUpdateRdv />,
      },
      {
        path: "rendez-vous/modifier-rendez-vous/:slug",
        element: <AddOrUpdateRdv />,
      },
      {
        path: "paiements",
        element: <PaiementAdmin />,
      },
      {
        path: "paiements/detail-paiement/:slug",
        element: <PaiementDetail />,
      },
      {
        path: "remboursements",
        element: <RemboursementAdmin />,
      },
      {
        path: "remboursements/detail-remboursement/:slug",
        element: <RemboursementDetail />,
      },
      // {
      //   path: "messages",
      //   element: <MessagerieAdmin socket={socket} />,
      // },
      {
        path: "messages",
        element: <MessagesContacts />,
      },
      {
        path: "messages/detail-message/:slug",
        element: <MessageContactDetail />,
      },
      {
        path: "mon-profil",
        element: <ProfilAdmin />,
      },
      {
        path: "parametres",
        element: <ParametreAdmin />,
      },
      {
        path: "parametres/ajouter-sous-admin",
        element: <AddAdmin />,
      },
      {
        path: "parametres/modifier-sous-admin/:slug",
        element: <AddAdmin />,
      },
      {
        path: "parametres/voir-sous-admin/:slug",
        element: <ShowAdmin />,
      },
      {
        path: "parametres/ajouter-condition",
        element: <AddOrUpdateCondition />,
      },
      {
        path: "parametres/modifier-condition/:slug",
        element: <AddOrUpdateCondition />,
      },
      {
        path: "parametres/ajouter-politique",
        element: <AddOrUpdatePolitique />,
      },
      {
        path: "parametres/modifier-politique/:slug",
        element: <AddOrUpdatePolitique />,
      },
      {
        path: "parametres/ajouter-apropos",
        element: <AddOrUpdateApropos />,
      },
      {
        path: "parametres/modifier-apropos/:slug",
        element: <AddOrUpdateApropos />,
      },
    ],
  },
];
